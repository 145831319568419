/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusOutlined } from "@ant-design/icons";
import { ThemeContext } from "@pages/_app";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import type { ReactNode } from "react";
import React, { useContext, useState } from "react";
import Tips from "../../../assets/tips-icon_svg.svg";
import { SCloseButton, SInfoCollapseBox } from "./StyledInfoCollapseBox";

interface InfoCollapseBoxProps extends CollapseProps {
  title?: string | React.ReactNode;
  isOpen?: boolean;
  icon?: ReactNode;
  noPadding?: boolean;
}

const InfoCollapseBox: React.FunctionComponent<InfoCollapseBoxProps> = ({
  title,
  isOpen = true,
  icon,
  children,
  noPadding,
  ...collapseProps
}) => {
  const [showInfo, setShowInfo] = useState(isOpen);
  const { theme, appModeTheme } = useContext(ThemeContext);

  return (
    <SInfoCollapseBox
      {...collapseProps}
      defaultActiveKey={showInfo ? "1" : "2"}
      style={{
        padding: noPadding ? 0 : "10px 5px",
        background: appModeTheme?.secondLayerBaseBackgroundColor,
        color: appModeTheme?.secondLayerTextColor
      }}
    >
      <Collapse.Panel
        collapsible="header"
        key="1"
        showArrow={false}
        style={{ color: "inherit" }}
        header={
          <div className="header" onClick={() => setShowInfo(prev => !prev)}>
            {icon !== null && (
              <div className="icon">
                {icon || (
                  <Tips
                    fill={theme.palette.primary}
                    height={40}
                    alt="icon-tips"
                  />
                )}
              </div>
            )}
            <h4 className="box-title">{title}</h4>
            <SCloseButton isOpen={showInfo}>
              <PlusOutlined />
            </SCloseButton>
          </div>
        }
      >
        <div className="content">{children}</div>
      </Collapse.Panel>
    </SInfoCollapseBox>
  );
};

export default InfoCollapseBox;
